import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import numberGenerator from "../../utils/numberGenerator";
import PortfolioProject from "../PortfolioProject/PortfolioProject";
import "./PortfolioProjectList.scss";
import Loader from "../Loader/Loader";

import background1_img from "../../assets/img/portfolio/bg_1.png";
import background2_img from "../../assets/img/portfolio/bg_2.png";
import background3_img from "../../assets/img/portfolio/bg_3.png";
import background4_img from "../../assets/img/portfolio/bg_4.png";
import background5_img from "../../assets/img/portfolio/bg_5.png";
import background6_img from "../../assets/img/portfolio/bg_6.png";

import preview1_img from "../../assets/img/portfolio/img_1.png";
import preview2_img from "../../assets/img/portfolio/img_2.png";
import preview3_img from "../../assets/img/portfolio/img_3.png";
import preview4_img from "../../assets/img/portfolio/img_4.png";
import preview5_img from "../../assets/img/portfolio/img_5.png";
import preview6_img from "../../assets/img/portfolio/img_6.png";

import restaurantPortfolioImg1
    from "../../assets/img/portfolio/restaurant-portfolio-1.png";
import restaurantPortfolioImg2
    from "../../assets/img/portfolio/restaurant-portfolio-2.png";
import restaurantPortfolioImg3
    from "../../assets/img/portfolio/restaurant-portfolio-3.png";

PortfolioProjectList.propTypes = {};

const DATA = [
    {
        _id: "1",
        previewBackground: background1_img,
        previewImage: preview1_img,
        title: "Driving+",
        images: []
    },
    {
        _id: "2",
        previewBackground: background2_img,
        previewImage: preview2_img,
        title: "11 Mirrors Rooftop Restaurant & Bar",
        images: [
            restaurantPortfolioImg1,
            restaurantPortfolioImg2,
            restaurantPortfolioImg3
        ]
    },
    {
        _id: "3",
        previewBackground: background6_img,
        previewImage: preview6_img,
        title: "UPSTART NETWORK",
        images: []
    },
    {
        _id: "4",
        previewBackground: background4_img,
        previewImage: preview4_img,
        title: "QLP",
        images: []
    },
    {
        _id: "5",
        previewBackground: background3_img,
        previewImage: preview3_img,
        title: "Центр Ridni",
        images: []
    },
    {
        _id: "6",
        previewBackground: background5_img,
        previewImage: preview5_img,
        title: "hiTurkey",
        images: []
    },
];

const PROJECTS_TO_SHOW = 3;


function PortfolioProjectList( props ) {
    const [isLoaded, setLoaded] = useState(true);
    const [visibleProjects, setVisibleProjects] = useState(PROJECTS_TO_SHOW);
    const projectList = DATA
        .slice(0, visibleProjects)
        .map(( {_id, previewImage, title, previewBackground} ) => {
            return (
                <li className="portfolio-projects__item">
                    <PortfolioProject
                        title={ title }
                        image={ previewImage }
                        key={ _id }
                        id={ _id }
                        background={ previewBackground }
                    />
                </li>
            );
        });
    const generator = useMemo(() => {
        return numberGenerator(PROJECTS_TO_SHOW, PROJECTS_TO_SHOW, DATA?.length);
    }, [PROJECTS_TO_SHOW]);

    const handleBtnClick = () => {
        setLoaded(false);
        setTimeout(() => {
            setLoaded(true);
            setVisibleProjects(generator.next().value);
        }, 1000);
    };
    return (
        <ul className="portfolio-projects wrapper">
            { projectList }
            {
                !isLoaded && (
                    <Loader
                        className={ {loader: "portfolio-projects__loader"} }/>
                )
            }
            { visibleProjects !== DATA?.length && <button
                className="portfolio-projects__btn"
                onClick={ handleBtnClick }
            >
                See more
            </button> }
        </ul>
    );
}

export default PortfolioProjectList;