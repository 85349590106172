import React from 'react';

import {PALETTE} from "../../constants";
import _ from "lodash";
import PropTypes from 'prop-types';
import ElementStyles from "../../utils/ElementStyles";

import "./Loader.scss"

Loader.propTypes = {
    fixed: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.shape({
        container: PropTypes.string,
        loaderBox: PropTypes.string,
        loader: PropTypes.string,
    }),
    size: PropTypes.number,
    thickness: PropTypes.number,
    position: PropTypes.shape({
        horizontal: PropTypes.oneOf(['center', 'left', 'right']),
        vertical: PropTypes.oneOf(['center', 'top', 'bottom'])
    }),
};

Loader.defaultProps = {
    fixed: false,
    color: PALETTE.PRIMARY_COLOR,
    className: {},
    size: 40,
    thickness: 3.6,
    position: {
        horizontal: 'center',
        vertical: 'center'
    }
};

const TRANSLATES = {
    horizontal: "translateX(-50%)",
    vertical: "translateY(-50%)",
}

/**
 *
 * @param {Boolean} fixed
 * @param {String} color
 * @param {Object} className
 * @param {Number} size
 * @param {Number} thickness
 * @param {Object} position
 * @returns {JSX.Element}
 * @constructor
 */

function Loader({
                    fixed,
                    color,
                    className,
                    size,
                    thickness,
                    position
                }) {
    const {container = "", loader = "", loaderBox = ""} = className;

    let containerClassName = `loader-container ${container}`;
    let loaderBoxClassName = `loader-box ${loaderBox}`;
    let loaderClassName = `loader ${loader}`;

    const loaderStyles = new ElementStyles({
        width: `${size}px`,
        height: `${size}px`,
        border: `${thickness}px solid #f3f3f3`,
        borderTop: `${thickness}px solid ${color}`,
    });

    const {
        loaderBoxStyles,
        containerClassName: containerClassNameNew,
        loaderBoxClassName: loaderBoxClassNameNew
    } = defineLoaderBoxStyles(fixed, containerClassName, loaderBoxClassName, position);

    loaderBoxClassName = loaderBoxClassNameNew;
    containerClassName = containerClassNameNew;

    return (
        <div className={containerClassName}>
            <div style={loaderBoxStyles.styles} className={loaderBoxClassName}>
                <div
                    style={loaderStyles.styles}
                    className={loaderClassName}/>
            </div>
        </div>
    );
}

/**
 *
 * @param {Boolean} fixed
 * @param {String} containerClassName
 * @param {String} loaderBoxClassName
 * @param {Object} position
 * @returns {{containerClassName, loaderBoxStyles: ElementStyles, loaderBoxClassName}}
 */
function defineLoaderBoxStyles (fixed, containerClassName, loaderBoxClassName, position){
    const loaderBoxStyles = new ElementStyles({});

    if (fixed) {
        containerClassName += ` loader-container-fixed`;
        loaderBoxClassName += ` loader-box-absolute`;
    }

    if (fixed && position !== null && typeof position === "object") {
        const {horizontal, vertical} = position;
        if (horizontal) {
            const horizontalClassName = _.kebabCase(`horizontal ${horizontal}`);
            loaderBoxStyles.add({transform: TRANSLATES.horizontal});
            loaderBoxClassName += ` ${horizontalClassName}`;
        }

        if (vertical) {
            const verticalClassName = _.kebabCase(`vertical ${vertical}`);
            loaderBoxStyles.add({transform: TRANSLATES.vertical});
            loaderBoxClassName += ` ${verticalClassName}`;
        }
    }

    return {
        loaderBoxStyles,
        containerClassName,
        loaderBoxClassName
    }
}

export default Loader;