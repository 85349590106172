import React from 'react';
import PropTypes from 'prop-types';
import Footer from "../components/Footer/Footer";
import PortfolioCarousel from "../components/PortfolioCarousel/PortfolioCarousel";
import ContactUs from "../components/ContactUs/ContactUs";
import Header from "../components/Header/Header";

import "./portfolio.scss"
import PortfolioProjectList from "../components/PortfolioProjectList/PortfolioProjectList";
import defineDevice from "../utils/defineDevice";
import useWindowSize from "../hooks/useWindowSize";
import {DEVICES} from "../constants";
import Loader from "../components/Loader/Loader";

Portfolio.propTypes = {};

function Portfolio(props) {
    const {width} = useWindowSize();
    const device = defineDevice(width);

    const isMobile = device === DEVICES.MOBILE || device === DEVICES.LARGE_MOBILE;

    return (
        <>
            <title>Bold</title>
            <Header/>
            <main className="portfolio-content">
                <section>
                    <div className="wrapper">
                        <h1 className="portfolio-content__heading page-heading">Portfolio</h1>
                    </div>
                    {isMobile && <PortfolioProjectList/>}
                    {!isMobile && <PortfolioCarousel/>}
                </section>
                <ContactUs/>
            </main>
            <Footer/>
        </>
    );
}

export default Portfolio;