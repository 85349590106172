/**
 * @generator
 * @param {Number} initialValue = 0
 * @param {Number} step = 1 - the number which will be added on each iteration
 * @param {Number} [maxValue]
 * @returns {Generator<*, void, *>}
 */
function* numberGenerator(initialValue = 0, step = 1, maxValue) {
    let number = initialValue;
    while (true) {
        if (typeof maxValue !== "number") {
            yield number += step;
        }

        if (number <= maxValue - step) {
            yield number += step;
        } else {
            const difference = maxValue - number;

            if (difference < step) {
                yield number += difference;
            } else {
                yield number
            }
        }

    }
}

export default numberGenerator;