import {DEVICE_BREAKPOINTS, DEVICES} from "../constants";

const {
    MOBILE_WINDOW_SIZE,
    LARGE_MOBILE_WINDOW_SIZE,
    TABLET_WINDOW_SIZE,
    DESKTOP_WINDOW_SIZE,
    LARGE_DESKTOP_WINDOW_SIZE,
    EXTRA_LARGE_DESKTOP_WINDOW_SIZE
} = DEVICE_BREAKPOINTS;

const {
    MOBILE,
    LARGE_MOBILE,
    TABLET,
    DESKTOP,
    LARGE_DESKTOP,
    EXTRA_LARGE_DESKTOP,
} = DEVICES;

/**
 *
 * @param width {Number} - window width
 * @returns {string}
 */
const defineDevice = (width) => {
    if (width >= EXTRA_LARGE_DESKTOP_WINDOW_SIZE) {
        return EXTRA_LARGE_DESKTOP;
    } else if (width >= LARGE_DESKTOP_WINDOW_SIZE) {
        return LARGE_DESKTOP;
    } else if (width >= DESKTOP_WINDOW_SIZE) {
        return DESKTOP;
    } else if (width >= TABLET_WINDOW_SIZE) {
        return TABLET;
    } else if (width >= LARGE_MOBILE_WINDOW_SIZE) {
        return LARGE_MOBILE;
    } else if (width >= MOBILE_WINDOW_SIZE) {
        return MOBILE;
    }
};

export default defineDevice;