/**
 * Creates styles for an element
 *
 * @class
 */
class ElementStyles {
    /**
     * @param {Object} styles
     */
    constructor(styles) {
        this.styles = styles;
    }

    /**
     * Adds new styles
     * @method
     * @param {Object} styles
     */
    add (styles) {
        const {styles: currentStyles} = this;
        this.styles = Object.keys(styles).reduce((newStyles, key) => {
            const existingStyles = currentStyles[key];
            if (existingStyles) {
                return {
                    ...currentStyles,
                    ...newStyles,
                    [key]: `${existingStyles} ${styles[key]}`
                }
            }

            return {
                ...currentStyles,
                ...newStyles,
                [key]: styles[key]
            }
        }, {})
    }
}

export default ElementStyles;