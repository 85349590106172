import {useEffect, useState} from "react";

const useWindowSize = () => {
    const isBrowser = typeof window !== "undefined";
    if (!isBrowser){
        return {
            width: undefined,
            height: undefined
        }
    }
    const [{width, height}, setSize] = useState({
        width: window && window?.innerWidth,
        height: window && window?.innerHeight
    });

    useEffect(() => {
        const changeWindowSize = () => {
            setSize({
                width: window && window?.innerWidth,
                height: window && window?.innerHeight
            })
        }

        window?.addEventListener("resize", changeWindowSize);

        return () => {
            window?.removeEventListener("resize", changeWindowSize);
        }
    }, [])

    return {
        width,
        height
    }
}

export default useWindowSize;